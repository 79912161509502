type EventKind = 'unauthorized'

class CRPEvents {
  private subscribeMap = new Map<EventKind, Array<Function>>()

  public subscribe(event: EventKind, fn: Function) {
    const subscribers = this.subscribeMap.get(event) || [];
    subscribers.push(fn);
    this.subscribeMap.set(event, subscribers);
  }

  public publish(kind: EventKind, data?: any) {
    const subscribers = this.subscribeMap.get(kind) || [];
    for (const subscriber of subscribers) {
      subscriber(data)
    }
  }
}

export const CRP_EVENTS = new CRPEvents();