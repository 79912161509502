import { FaCircleUser } from "react-icons/fa6";
import { PiCherriesDuotone } from "react-icons/pi";
import styled from "styled-components";

import { FiCopy } from "react-icons/fi";
import Logo from "../../components/logo";


export const Chat = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const ChatIndicators = styled.div`
  padding: 0 15px;
  height: 0;
  position: relative;
`

export const ChatBlock = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 20px;
  overflow: auto;
  scroll-behavior: smooth;
  padding-top: 48px;

  /* width */
  &::-webkit-scrollbar {
    height: 1px;
    width: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 9999px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #b6bbbc;
    border-color: #894141;
    border-radius: 9999px;
    border-width: 1px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;



export const MessageBox = styled.div`
  padding: 0 10px 10px;
`;

export const Img = styled.img`
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  filter: blur(12px) opacity(.2);
  flex: 1;
  height: calc(100% - 132px);
  object-fit: contain;
  pointer-events: none;
`;



export const NewChat = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`

export const Messages = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  gap: 20px;
`;

export const Message = styled.div<{ delay?: number }>`
  display: flex;
  gap: 5px;

  animation: ${({ delay }) => delay ?? 0.1}s ease-in-out 0s 1 TypingAppearance;

  @keyframes TypingAppearance {
    0% {
      opacity: 0;
      transform: translate(0, 50%);
    }
    100% {
      opacity: 1;
      transform: translate(0, 0%);
    }
  }
`;

export const Typing = styled.div`
  flex-direction: column;
`;

export const DelayWarning = styled.div`
  display: flex;
  gap: 10px;
  opacity: 0;
  animation: 0.5s ease-in-out 3s 1 DelayAppearance;
  animation-fill-mode: forwards;
  font-size: 10px;
  color: #616161;
  font-weight: 500;
  padding: 10px 0;

  @keyframes DelayAppearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const MessageAvatar = styled.div`
  display: flex;
`;

export const MessageBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MessageTitle = styled.p`
  display: flex;
  font-weight: bold;
  text-transform: capitalize;
`;

export const MessageText = styled.div`
  /* display: flex; */
`;

export const Jumbotron = styled.div`
  opacity: 0.6;
  min-width: 80%;
  max-width: 80%;
  background-color: white;
  border-radius: 12px;
  display: flex;
  padding: 20px;
  
  @media (max-width:320px) {
    min-width: initial;
    max-width: initial;
    margin: 0 10px;
  }

  @media (max-width:481px)  {
    min-width: initial;
    max-width: initial;
    margin: 0 10px;
  }

`;

export const EmptyBlock = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 0;
  padding-top: 48px;
  overflow: auto;
  justify-content: center;
  align-items: center;

  @media (max-width:320px) {
    justify-content: normal;
  }

  @media (max-width:481px)  {
    justify-content: normal;
  }
`

export const SystemAvatar = styled.div`
  opacity: 0.5;

  * {
    zoom: 0.7;
   }
`
export const UserAvatar = styled(FaCircleUser)`
  color: #386790;
  margin: 2px;
`

export const ThumbnailBlock = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  
  &:hover {
    color: white;
  }
`

export const ThumbnailLink = styled.a`
  color: white;

  &:hover {
    color: white;
  }

`

export const ThumbnailImg = styled.img`
  /* max-width: 50%; */
  max-width: 100%;
  /* max-height: 50%; */
  object-fit: contain;
  border-radius: 16px;
`;

export const CopyBar = styled.div`
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    position: absolute;
    top: 5px;
    gap: 10px;
    left: 5px;
    background-color: #00000092;
    border-radius: 20px;
    padding: 6px 13px;
    /* user-select: all; */
    font-size: 0.5rem;
    backdrop-filter: blur(15px);
    z-index: 100;
`


export const CopyLink = styled.a`
  color: white;
  cursor: pointer;
`
export const CopyIcon = styled(FiCopy)`
  color: white;
`