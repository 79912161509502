import React, { FC, useCallback, useState } from 'react'

import * as s from './chat.styles';
import { useChatModel } from './chat.model';
import { MessageBox } from '../../components/message-box';
import toast from 'react-hot-toast';
import { ChatMessage } from '../../api';
import { FaCircleUser } from "react-icons/fa6";
import Loading from '../../components/loading';
import TypingIndicator from '../../components/typing-indicator';
import Markdown from 'react-markdown';
import Logo from '../../components/logo';

interface MessageProps {
  message: Partial<ChatMessage>;
  typing?: boolean;
}

export const Message: FC<MessageProps> = ({ message, typing }) => {
  return (
    <s.Message delay={typing ? 1 : undefined}>
      <s.MessageAvatar>
        {
          message.author === "user"
            ? <s.UserAvatar fontSize={20} />
            : <s.SystemAvatar><Logo /></s.SystemAvatar>
        }
      </s.MessageAvatar>
      <s.MessageBody>
        <s.MessageTitle>
          {
            message.author === "user"
              ? "You"
              : "Scriptube"
          }
        </s.MessageTitle>
        <s.MessageText>
          {
            typing
              ? <s.Typing>
                <TypingIndicator />
                <s.DelayWarning>Depending on the video length it may take up to 3-4 minutes to download and analyze.</s.DelayWarning>
              </s.Typing>
              : <Markdown>{message.message}</Markdown>
          }
        </s.MessageText>
        <s.MessageText>
          {
            message.meta?.videoUrl && message.meta?.imageUrl &&
            <VideoTile videoUrl={message.meta.videoUrl} thumbnailUrl={message.meta.imageUrl} />
          }
        </s.MessageText>
      </s.MessageBody>
    </s.Message>
  )
}

interface VideoTileProps {
  thumbnailUrl: string;
  videoUrl: string;
}

export const VideoTile: FC<VideoTileProps> = ({ thumbnailUrl, videoUrl }) => {
  return (
    <s.ThumbnailBlock>
      <s.CopyBar>
        <s.ThumbnailLink href={videoUrl} target='_blank'>
          {videoUrl}
        </s.ThumbnailLink>
        <s.CopyLink onClick={() => { navigator.clipboard.writeText(videoUrl) }}><s.CopyIcon /> </s.CopyLink>
      </s.CopyBar>

      <s.ThumbnailLink href={videoUrl} target='_blank'>
        <s.ThumbnailImg src={thumbnailUrl} />
      </s.ThumbnailLink >
    </s.ThumbnailBlock>
  )
}