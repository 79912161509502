import { FC } from "react";
import { Outlet, createBrowserRouter, RouterProvider } from "react-router-dom";

import { Boot } from '../modules';
import { Authenticator } from "../modules/auth";

import { HomeScreen } from "./home";
import { ChatScreen } from "./chat";
import { NoMatchScreen } from "./no-match";

import * as s from './styles';
import { Toaster } from "react-hot-toast";
import { Header } from "./Header";
import { ErrorBoundary } from "./ErrorBoundary";

export const Pages = {
  home: {
    path: "/",
    index: true,
    element: HomeScreen,
    navigate() {
      router.navigate('/');
    },
  },
  chat: {
    path: "/:chatId",
    element: ChatScreen, params: { chatId: "" },
    url: (chatId: string) => `/${chatId}`,
    navigate(chatId: string, state?: any) {
      router.navigate(this.url(chatId), state);
    },
  },
}

const Root = () => {
  return (
    <Boot>
      <Header />
      <Outlet />
      <Authenticator />
    </Boot>
  );
}

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <HomeScreen />,
        index: true,
      },
      {
        path: "/:chatId",
        element: <ChatScreen />,
      },
      {
        path: "*",
        element: <NoMatchScreen />
      },
    ]
  }
])

const App: FC = () => {
  return (
    <s.App>
      <Toaster />
      <RouterProvider router={router} />
    </s.App>
  )
}

export default App