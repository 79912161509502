import React, { FC } from 'react'
import * as s from './styles'

const TypingIndicator: FC = ({ ...props }) => {
  return (
    <s.Container {...props}>
      <s.Block>
        <s.Dot />
        <s.Dot />
        <s.Dot />
      </s.Block>
    </s.Container>
  )
}


export default TypingIndicator