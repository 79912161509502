import { Action, Model, connect } from "react-storm";
import api from "../../api";
import { CRP_EVENTS } from "../@events";

@Model()
export class AuthModel {

  // public me?: UserBoot
  public anonymous = true;
  public showAuthentificator = false;

  public isAuthenticated = false

  public async init() {
    CRP_EVENTS.subscribe('unauthorized', () => {
      this.showeAuth();
    })

    try {
      const { data } = await api.auth.token();

      this.anonymous = data.anonymous
      this.isAuthenticated = true;

      if (!this.anonymous) {
        // await this.retrieveCurrentUser()
      }
    } catch (error) {
      console.error(error)
    }
  }

  // @Action()
  // public async authCallback(response: IdentityCallbackPayload) {
  //   await api.auth.idpCallback({
  //     provider: response.provider,
  //     payload: response.payload
  //   }, { withCredentials: true });

  //   window.location.reload();
  // }

  // @Action()
  // public ensureAuth(): boolean {
  //   if (this.anonymous) {
  //     this.showAuthentificator = true;
  //     // throw new Error('Action requires authentication.');
  //   }

  //   return !this.showAuthentificator
  // }

  @Action()
  public showeAuth() {
    this.showAuthentificator = true;
  }

  // private async retrieveCurrentUser() {
  //   const me = await api.user.getMe();
  //   this.me = me.data
  // }
}

const [AuthScope, useAuthModel] = connect(AuthModel)

export { AuthScope, useAuthModel }
