import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: #d4d4d40f;
`;

export const Drawer = styled.div`
  background-color: #fff;
  padding: 10px;
  border-radius: 2px;
  bottom: 10px;
  position: absolute;
  height: 100px;
  width: 90%;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  margin: auto 0;
  left: 50%;
  transform: translate(-50%, 0);
  animation: 0.2s ease-out 0s 1 slideInFromLeft;

  @keyframes slideInFromLeft {
    0% {
      transform: translate(-50%, 100%);
    }
    100% {
      transform: translate(-50%, 0);
    }
  }
`;