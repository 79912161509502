

import { IoCreateOutline } from "react-icons/io5";

import * as s from './styles';
import { useLocation } from "react-router-dom";
import { Pages } from "./App";
import Logo from "../components/logo";

export const Header = () => {
  const l = useLocation();

  const isHomePage = l.pathname === '/'

  return (
    <s.HeaderContainer>
      <s.Header>
        <s.LogoLink href="/">
          <Logo /> ScripTube
        </s.LogoLink>
        <s.Toolbar>
          {
            !isHomePage &&
            <s.NewChat onClick={() => Pages.home.navigate()}>
              New <IoCreateOutline size={16} />
            </s.NewChat>
          }
        </s.Toolbar>
      </s.Header>
    </s.HeaderContainer>
  );
}