import { Action, Model, connect } from "react-storm";
// import api, { IdentityCallbackPayload, UserBoot } from "../../api";
// import { TLR_EVENTS } from "../@events";
import { Pages, router } from "../../app/App";
import api, { setAuthHeader } from "../../api";
import { AppStorage } from "../../libs/app-storage";
import { Jwt } from "../../libs/jwt";
// import { requestTellusByTag } from "../../api/fake-api";

@Model()
export class HistoryModel {


  public async init() {
    try {
      
    } catch (error) {
      console.error(error)
    }
  }

}

const [HistoryScope, useHistoryModel] = connect(HistoryModel)

export { HistoryScope, useHistoryModel }
