import React, { FC, PropsWithChildren, } from 'react'
import * as s from './styles'

interface DrawerProps {
  show?: boolean
}

const Drawer: FC<PropsWithChildren<DrawerProps>> = ({ show, children, ...rest }) => {

  if (!show) return null

  return (
    <s.Container {...rest}>
      <s.Drawer>
        {children}
      </s.Drawer>
    </s.Container>
  )
}

export default Drawer
