import styled from "styled-components";

export const App = styled.div`
  height: 100%;
  /* background-color: ${(props) => props.theme.bg}; */
  background: radial-gradient(#F6F8FF, #d0d6e2);
  display: flex;
  flex-direction: column;
`
export const HeaderContainer = styled.div`
  display: flex;
  position: relative;
`

export const Header = styled.div`
  display: flex;
  flex: 1;
  max-width: 48rem;
  padding: 5px 10px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  backdrop-filter: blur(6px);
  z-index: 1000;
`
export const Logo = styled.h3`
  flex: 1;
  color: black;
  text-shadow: 0 0 1px black;
`

export const Toolbar = styled.div`
`

export const NewChat = styled.button`
    border: none;
    border-radius: 12px;
    padding: 3px 16px 3px 16px;
    line-height: 26px;
    font-family: inherit;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    align-self: end;
    transition-duration: 0.4s;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    gap: 7px;

    &:hover {
      transition-duration: 0.1s;
      background-color: #d1d0d0;
    }

    &:active {
      transform: translateY(1px)
    }
`

export const RootError = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
`


export const LogoLink = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    color: #2b2f32;
    gap: 10px
`