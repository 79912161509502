import { AxiosInstance } from "axios";
import { Api, ApiConfig } from "./cherrypick-api";
import { router } from "../app/App";
import { CRP_EVENTS } from "../modules/@events";
import ReconnectingEventSource from "reconnecting-eventsource";
// import { TLR_EVENTS } from "../modules";

class CrpApi extends Api<unknown> {
  constructor(props: ApiConfig<unknown> = {}) {
    super(props);
  }

  public chat_sse(chatId: string) {
    return new EventSource(`${this.instance.defaults.baseURL}/chat/${chatId}/sse`, { withCredentials: true });
  }
}

const api = new CrpApi({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// eslint-disable-next-line @typescript-eslint/dot-notation
const axiosInstance = api['instance'] as AxiosInstance;

let authInterceptor: number | null = null;
export const setAuthHeader = (token: string | null) => {
  if (authInterceptor !== null) {
    axiosInstance.interceptors.request.eject(authInterceptor);
  }
  authInterceptor = axiosInstance.interceptors.request.use((request) => {
    request.headers.Authorization = token ? `Bearer ${token}` : undefined;
    return request;
  });
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      CRP_EVENTS.publish('unauthorized')
    } else {
      return Promise.reject(error);
    }
  },
);

export * from './cherrypick-api';
export default api;