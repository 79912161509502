import { FC, PropsWithChildren } from 'react'
import { AuthScope } from './auth';
import Loading from '../components/loading';
import { HistoryScope } from './history';

export const Boot: FC<PropsWithChildren> = ({ children }) => {

  return (
    <AuthScope loading={<Loading />}>
      <HistoryScope loading={<Loading />}>
        {children}
      </HistoryScope>
    </AuthScope>
  )
}