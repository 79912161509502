import styled from "styled-components";
import { CgSpinner } from "react-icons/cg";
import { TiArrowUpThick } from "react-icons/ti";



export const MessageBox = styled.div`
  display: flex;
  border-radius: 4px;
  padding: 10px;
  border-radius: 12px;
  /* box-shadow: inset 0 0 0 2px #ee8dbb; */
  /* box-shadow: 0 0 42px 8px #32323236; */
  box-shadow: 0 0 0px 6px #ffffff36;
  background-color: white;
  align-items: center;
`

export const Input = styled.textarea`
    font-family: inherit;
    padding: 0 10px;
    resize: none;
    /* resize: horizontal; */
    overflow: hidden;
    flex: 1;
    outline: none;
    border: none;
    color: #000000;
    background-color: inherit;
    font-size: 14px;

    ::placeholder {
      color: #fbfbfb87;
      opacity: 1; /* Firefox */
    }
`;

export const Spinner = styled(CgSpinner)`
  animation: rotate 0.5s linear infinite; 
  font-size: 16px;
  vertical-align: middle;
  margin-left: 5px;

  @keyframes rotate {
    to { transform: rotate(360deg); }
  }
`
export const SendIcon = styled(TiArrowUpThick)`
  font-size: 20px;
  vertical-align: middle;
  color: #2e2e2e;
`


export const Button = styled.button`
  border: none;
  border-radius: 12px;
  box-shadow: inset 0 0 0 1px #E1E6F9;
  padding: 9px 15px;
  line-height: 26px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  align-self: end;
  transition-duration: 0.4s;
  background-color: #f0f0f0;
  outline:0;


  &:disabled {
    opacity: 0.5;
  }

  &:hover:enabled {
    transition-duration: 0.1s;
    background-color: #E1E6F9;
  }

  &:active:enabled {
    transform: translateY(1px);
  }
`

