import styled from "styled-components";
import Drawer from "../../components/drawer";
import * as s from "../../components/drawer/styles";

export const AnonymousPromt = styled.div`
  color: white;
  position: fixed;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(7px);
  width: 100%;
  height: 100%;
`


export const DarkDrawer = styled(Drawer)`
  ${s.Drawer} {
    background-color: #292D32;
    border-radius: 10px;
  }
`
