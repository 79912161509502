import React, { useCallback, useEffect, useRef, useState } from 'react'

import * as s from './chat.styles';
import { useChatModel } from './chat.model';
import { MessageBox } from '../../components/message-box';
import toast from 'react-hot-toast';
import { Message } from './message';
import TypingIndicator from '../../components/typing-indicator';

export const Chat = () => {
  const messagesDivRef = useRef<HTMLDivElement>(null);
  const model = useChatModel(({ sendMessage, messages, typing, freshLoad, thumbnailUrl }) => ({ sendMessage, messages, typing, freshLoad, thumbnailUrl }));

  useEffect(() => {
    if (messagesDivRef.current && !model.freshLoad) {
      messagesDivRef.current.scrollTop = messagesDivRef.current.scrollHeight + messagesDivRef.current.clientHeight;
    }
  }, [model.messages, model.freshLoad])

  const onSendMessage = useCallback(async (message: string) => {
    const res = await model.sendMessage(message)
    if (typeof res === 'string') {
      toast.error(res)
      return false;
    } else {
      return true;
    }
  }, [model])

  return (
    <s.Chat>
      <s.ChatBlock ref={messagesDivRef}>
        <s.Img src={model.thumbnailUrl} />
        <s.Messages >
          {model.messages.map(x => <Message message={x} />)}
          {model.typing && <Message message={{ author: "system" }} typing={true} />}
        </s.Messages>
      </s.ChatBlock>
      <s.MessageBox>
        <MessageBox onSubmit={onSendMessage} placeholder='Ask about the Video' />
      </s.MessageBox>
    </s.Chat>
  )
}
