import React, { FC, useState } from 'react'
// import {
//   LoginSocialGoogle,
//   LoginSocialAmazon,
//   LoginSocialFacebook,
//   LoginSocialGithub,
//   LoginSocialInstagram,
//   LoginSocialLinkedin,
//   LoginSocialMicrosoft,
//   LoginSocialPinterest,
//   LoginSocialTwitter,
//   LoginSocialApple,
//   IResolveParams,
// } from 'reactjs-social-login';

// import {
//   FacebookLoginButton,
//   GoogleLoginButton,
//   GithubLoginButton,
//   AmazonLoginButton,
//   InstagramLoginButton,
//   LinkedInLoginButton,
//   MicrosoftLoginButton,
//   TwitterLoginButton,
//   AppleLoginButton,
// } from 'react-social-login-buttons';

// import { IdentityProviders } from '../../api'
import { useAuthModel } from './auth.model'

import * as s from './styles'

export const Authenticator: FC = () => {
  const { showAuthentificator, } =
    useAuthModel(({ showAuthentificator }) =>
      ({ showAuthentificator, }))

  return (
    <s.DarkDrawer show={showAuthentificator}>
      Login
      {/* //   <LoginSocialGoogle
    //     client_id={'514543589000-omamtu3o67o0urt7lfn43vp2m9hjo4ae.apps.googleusercontent.com'}
    //     onLoginStart={() => { console.log('onLoginStart') }}
    //     redirect_uri={"http://localhost:3000"}
    //     scope="openid profile email"
    //     isOnlyGetToken={true}
    //     typeResponse='idToken'
    //     discoveryDocs="claims_supported"
    //     // access_type="offline"
    //     onResolve={({ provider, data }: IResolveParams) => {
    //       console.log(provider, data);
    //       authCallback({ provider: IdentityProviders.Google, payload: data })
    //     }}
    //     onReject={err => {
    //       console.log(err);
    //     }}
    //   >
    //     <GoogleLoginButton />
    //   </LoginSocialGoogle> */}
    </s.DarkDrawer>
  )
}