
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Chat, ChatContext } from '../../modules/chat';
import { Pages } from '../App';
import * as s from './styles';

export const ChatScreen = () => {
  const { chatId } = useParams<typeof Pages.chat.params>();
  // const { state } = useLocation();

  console.log('chatId', chatId);

  return (
    <s.Chat>
      <s.Body>
        <ChatContext chatId={chatId}>
          <Chat />
        </ChatContext>
      </s.Body>
    </s.Chat>
  )
}