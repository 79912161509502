
import { useNavigate } from 'react-router-dom';

import * as s from './styles';
import { ChatContext, NewChat } from '../../modules/chat';
import { useAuthModel } from '../../modules/auth';
import { useEffect } from 'react';

export const HomeScreen = () => {
  const navigate = useNavigate();
  const model = useAuthModel(({ isAuthenticated }) => ({ isAuthenticated }))

  if (!model.isAuthenticated) return null;

  return (
    <s.Home>
      <s.Body>
        <ChatContext>
          <NewChat />
        </ChatContext>
      </s.Body>
    </s.Home>
  )
}