import { useRouteError } from "react-router-dom";
import * as s from './styles';

export function ErrorBoundary() {
  const error = useRouteError();

  return (
    <s.RootError>
      Ooops, something went wrong.
      <p>
        <a href="/">To Home page</a>
      </p>
    </s.RootError>
  )
}