import React, { ChangeEvent, KeyboardEvent, FC, useCallback, useRef, useState } from 'react'

import * as s from './styles';
import toast from 'react-hot-toast';
import { CgSpinner } from "react-icons/cg";


export interface MessageBoxProps {
  onSubmit: (message: string) => Promise<boolean>
  placeholder?: string
}

export const MessageBox: FC<MessageBoxProps> = ({ onSubmit, placeholder = 'Video URL' }) => {
  const [value, setValue] = useState("")
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const onValueChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;

    setSubmitDisabled(!e.target.value)
    setValue(e.target.value);
  }, [])

  const onKeyDown = useCallback(async (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === "Enter" && !e.shiftKey) {
      onStart()
      e.preventDefault();
    }
  }, [value])

  const onStart = useCallback(async () => {
    setLoading(true)
    if (await onSubmit(value)) {
      setValue('');
    }
    setLoading(false);
  }, [onSubmit, value])



  return (
    <s.MessageBox>
      <s.Input value={value} onChange={e => onValueChange(e)} rows={1} placeholder={placeholder} onKeyDown={onKeyDown} />
      <s.Button disabled={submitDisabled || loading} onClick={() => { onStart() }}>
        {!loading ? <s.SendIcon /> : <s.Spinner />}
      </s.Button>
    </s.MessageBox>
  )
}
