import styled from "styled-components";

export const Container = styled.div`
  
`

export const Block = styled.div`
  align-items: center;
  display: flex;
  height: 17px;
`

export const Dot = styled.div`
  background-color: #ffffff;
  animation: mercuryTypingAnimation 1s infinite ease-in-out;
  border-radius: 2px;
  display: inline-block;
  height: 6px;
  margin-right: 2px;
  width: 6px;
  box-shadow: 0 0 3px #c3c3c3;

  @keyframes mercuryTypingAnimation{
    0% {
      transform:translateY(0px)
    }
    28% {
      transform:translateY(-5px)
    }
    44% {
      transform:translateY(0px)
    }
  }

  &:nth-child(1){
    animation-delay: 200ms;
  }
  &:nth-child(2){
    animation-delay: 300ms;
  }
  &:nth-child(3){
    animation-delay: 400ms;
  }
`