import { Action, Model, connect } from "react-storm";
import api, { ChatMessage, NewChatResponse } from "../../api";
import { validateURL } from "../../libs/ytb-links";
import { Pages } from "../../app/App";

@Model()
export class ChatModel {

  private events!: EventSource;

  public freshLoad = true;
  public answer = '';
  public chatId!: string;
  public thumbnailUrl!: string;
  public messages: ChatMessage[] = [];

  public get typing() { return this.messages.at(-1)?.author === 'user' };

  public async init({ chatId }: any) {
    this.chatId = chatId;
    if (chatId) {
      await this.loadChat()
      this.connectSse();
    }
  }

  // public async udpate(params: any) {
  //   console.log('params-update', params)
  // }

  @Action()
  public async startChat(text: string) {
    if (!validateURL(text)) {
      return 'Incorrect video URL';
    }

    const newChat = await api.chat.startNewChat({ videoUrl: text });
    this.chatId = newChat.data.id
    this.messages = newChat.data.messages
    this.thumbnailUrl = newChat.data.thumbnailUrl

    this.connectSse();

    Pages.chat.navigate(this.chatId);
  }

  @Action()
  public async sendMessage(text: string) {
    if (!text) {
      return 'Incorrect Message';
    }

    await api.chat.sendMessage(this.chatId, { message: text });
  }

  @Action()
  private async onMessage(message: ChatMessage) {
    this.freshLoad = false;
    this.messages = [...this.messages, message];
  }

  private async loadChat() {
    const res = await api.chat.getChat(this.chatId);
    this.messages = res.data.messages
    this.thumbnailUrl = res.data.thumbnailUrl
  }

  private connectSse() {
    this.events = api.chat_sse(this.chatId);

    this.events.onmessage = ({ data }) => {
      console.log('New message', JSON.parse(data));
      this.onMessage(JSON.parse(data))
    };

    this.events.addEventListener('open', (o) => {
      console.log("The connection has been established.");
    });

    this.events.addEventListener('error', (e) => {
      console.log("Some erorro has happened");
      console.log(e);
    });

    this.events.addEventListener('message', (m) => {
      const newOder = JSON.parse(m.data);
      console.log(newOder);
    });
  }
}

const [ChatContext, useChatModel] = connect(ChatModel);

export { ChatContext, useChatModel };