import styled from "styled-components";

export const Home = styled.div`
  display: flex;
  width: 100%;
  height: 0;
  flex: 1;
`

export const Header = styled.div`
  padding: 15px;
  z-index: 1;
  top: 0;
  position: fixed;
  background: linear-gradient(0deg,rgba(17, 18, 20,0) 0%, rgba(17, 18, 20, 1) 70%);
`

export const Body = styled.div`
  display: flex;
  flex: 1;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
`

