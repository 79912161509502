import React from 'react'
import './style.scss'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  .loader::after {
    background-color: ${(props) => props.theme.bg};
  }
`


const Loading = () => {

  return (
    <Container>
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      {/* <div className="container">
        <div className="load21"></div>
        <div className="load22"></div>
        <div className="load23"></div>
        <div className="load24"></div>
        <div className="load25"></div>
      </div> */}
    </Container>
  )
}

export default Loading