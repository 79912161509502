import React, { useCallback, useState } from 'react'
import toast from 'react-hot-toast';

import * as s from './chat.styles';
import { useChatModel } from './chat.model';

import { MessageBox } from '../../components/message-box';
import Logo from '../../components/logo';

export const NewChat = () => {
  const model = useChatModel(({ startChat }) => ({ startChat }));

  const onStart = useCallback(async (message: string) => {
    const res = await model.startChat(message)
    if (typeof res === 'string') {
      toast.error(res)
      return false;
    } else {
      return true;
    }
  }, [model])

  return (
    <s.Chat>
      <s.EmptyBlock>
        <s.Jumbotron>
          <div>
            <h3>Welcome to ScripTube!</h3>
            <p>
              Are you tired of spending hours watching videos just to get to the key points? Welcome to ScripTube, your go-to solution for extracting concise and comprehensive summaries from any video link. Whether you're a student, a professional, or just someone looking to save time, our system makes it easy to quickly understand the essence of any video content.
            </p>
            <br />
            <p>
              <strong>Here's how it works:</strong>
            </p>
            <ol>
              <li><strong>Simple Input</strong>: Provide us with the link to the video you want summarized.</li>
              <li><strong>Advanced Analysis</strong>: Our advanced algorithms process the video, analyzing audio and visual content to identify crucial information.</li>
              <li><strong>Concise Summary</strong>: Receive a clear, concise summary that captures the main points, saving you time and effort.</li>
            </ol>
            <p>
              No more sifting through lengthy videos for the information you need. With ScripTube, get straight to the point and make the most of your time. Try it now and experience the future of video consumption!
            </p>
          </div>
        </s.Jumbotron>
      </s.EmptyBlock>
      <s.MessageBox>
        <MessageBox onSubmit={onStart} />
      </s.MessageBox>
    </s.Chat>
  )
}
